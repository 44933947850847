import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { logo, linkedIn } from "assets";
import { useForm } from "react-hook-form";

function ContactForm() {
  const initState = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    notes: "",
  };
  const [initialValues, setInitialValues] = useState(initState);
  const onSubmit = (values) => {
    // console.log("Values:::", values);
    // console.log("Values:::", JSON.stringify(values));
  };
  const onError = (error) => {
    // console.log("ERROR:::", error);
  };
  const {
    register,
    handleSubmit,
    // getValues,
    //watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues,
  });

  return (
    <>
      <div id="contact" className="section bg-contact">
        <Container>
          <Row>
            <Col md={6}>
              <h2 className="section-heading ft-700 ft-64  ">Wanna talk?</h2>
              <p className="lh-24 ft-24">
                Drop an email to&nbsp;
                <a href="mailto:inc@mesmr.io">inc@mesmr.io</a>
                &nbsp;or just fill this form and
                <br />
                we will get in touch with you.
              </p>
            </Col>
            <Col md={6}>
              <Form
                className="contact-form"
                noValidate
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <Row>
                  <Col>
                    {/* <Form.Group
                      as={Row}
                      className="mb-3 c-row"
                      controlId="contactForm.name"
                    >
                      <Form.Label column sm="2">
                        Name:
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          {...register("name", {
                            required: "enter the name field",
                          })}
                        />
                        {errors.name && (
                          <Form.Text className="text-danger">
                            {errors.name.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3  c-row"
                      controlId="contactForm.email"
                    >
                      <Form.Label column sm="2">
                        Email:
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="email"
                          {...register("email", {
                            required: "email field is required",
                          })}
                        />
                        {errors.email && (
                          <Form.Text className="text-danger">
                            {errors.email.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3  c-row"
                      controlId="contactForm.email"
                    >
                      <Form.Label column sm="2">
                        Phone:
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          {...register("phone", {
                            required: "phone field is required",
                          })}
                        />
                        {errors.phone && (
                          <Form.Text className="text-danger">
                            {errors.phone.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3  c-row"
                      controlId="contactForm.subject"
                    >
                      <Form.Label column sm="2">
                        Subject:
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          {...register("subject", {
                            required: "subject field is required",
                          })}
                        />
                        {errors.subject && (
                          <Form.Text className="text-danger">
                            {errors.subject.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3  c-row"
                      controlId="contactForm.notes"
                    >
                      <Form.Label column sm="2">
                        Notes:
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          {...register("notes")}
                        />
                      </Col>
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button type="submit">Submit form</Button>
                    </div> */}
                    <iframe className="ct-form" src="https://form.mesmr.io/" />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactForm;
