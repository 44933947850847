import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/Mesmer-logo.svg";
import React, { useEffect, useState } from "react";

function Header() {
  const [headerColor, setHeaderColor] = useState("menu-bg");
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);
  const handleSelect = () => setExpanded(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeaderColor("menu-bg");
    } else if (window.scrollY > 70) {
      return setHeaderColor("menu-bg dark");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (

    <Navbar expand="lg" expanded={expanded} className="fixed-top">
      <Container className={headerColor}>
        <Navbar.Brand href="/">
          <img src={logo} className="brand-Logo" alt-text="brand-logo" />
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto" onSelect={handleSelect}>
            <Nav.Link href="/#about">Who we are</Nav.Link>
            <Nav.Link href="/#capabilities">Capabilities</Nav.Link>
            <Nav.Link href="/#services">Services </Nav.Link>
            <Nav.Link href="/#clients">Clients </Nav.Link>
            <Nav.Link href="/#partners">Partners </Nav.Link>
            <Nav.Link href="/#portfolio">Portfolio </Nav.Link>
          </Nav>
          <div className="d-none d-sm-block">
            <a className="btn btn-chat" href="#contact">
              Contact
            </a>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
