import React from "react";

import {
  cover_1,
  cover_2,
  cover_3,
  cover_4,
  cover_5,
  cover_6,
} from "../assets";
export const slideOne = [
  {
    index: "in-1",
    title: "UPS",
    image: cover_1,
    para: "A cinematic video for the Universal Peace Sanctuary.",
    videoUrl:
      "https://player.vimeo.com/video/844160067?h=632ad46e9e&badge=0&autopause=0&player_id=0&app_id=58479",
  },
  {
    index: "in-2",
    title: "NIRVANA HOLDINGS",
    image: cover_2,
    para: "A cinematic promotional project for Nirvana Holdings executed in Unreal Engine.",
    videoUrl:
      "https://player.vimeo.com/video/843903494?h=4ea7d7ac19&badge=0&autopause=0&player_id=0&app_id=58479",
  },
  {
    index: "in-3",
    title: "MANTIA",
    image: cover_3,
    para: "A Long Format Immersive Annual Report for Mantia.",
    videoUrl:
      "https://player.vimeo.com/video/843887713?h=45fe871362&badge=0&autopause=0&player_id=0&app_id=58479",
  },
];
export const slidetwo = [
  {
    title: "JEEP",
    image: cover_4,
    para: "An Immersive Cinematic advert for Jeep.",
    videoUrl:
      "https://player.vimeo.com/video/847960957?h=047a21cd07&badge=0&autopause=0&player_id=0&app_id=58479",
  },
  {
    title: "ATHAR",
    image: cover_5,
    para: "An Ai Assisted Immersive Document for Athar.",
    videoUrl:
      "https://player.vimeo.com/video/852388348?h=ab506a2da7&badge=0&autopause=0&player_id=0&app_id=58479",
  },
  {
    title: "EMIRATES",
    image: cover_6,
    para: "An Immersive video for Emirates Airlines",
    videoUrl:
      "https://player.vimeo.com/video/847974605?h=fb53790adb&badge=0&autopause=0&player_id=0&app_id=58479",
  },
];
