import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { logo, linkedIn } from "assets";
function Footer() {
  return (
    <div className="footer-section">
      <Container>
        <Row className="pt-4">
          <Col md={6} className="f-brand-logo">
            <img
              src={logo}
              className="footer-brand-Logo"
              alt-text="brand-logo"
            />
          </Col>
          <Col md={6} className=" col-md-6 d-flex f-helper-text ">
            <p className="m-0">
              It all begins with a <span className="ft-700">PIXEL</span>
            </p>
          </Col>
        </Row>
        <Row className="ct-block py-4">
          {/* <Col>
              <h5>Our Ecosystem</h5>
              <ul className="contact-list">
                <li>
                  <a href="#capabilities">Capabilities</a>
                </li>
                <li>
                  <a href="#services">Services </a>
                </li>
                <li>
                  <a href="#clients">Clients </a>
                </li>
                <li>
                  <a href="#partners">Partners </a>
                </li>
                <li>
                  <a href="#portfolio">Portfolio </a>
                </li>
                <li>
                  <a href="">Our Services</a>
                </li>
              </ul>
            </Col> */}
          {/* <Col>
              <h5>Company</h5>
              <ul className="contact-list">
                <li>
                  <a href="#about">About</a>
                </li>

                <li>
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </Col> */}
          <Col className="ft-white" xs={12} md={6}>
            <ul className="list-inline">
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/cookie">Cookie Policy</a>
              </li>
            </ul>
          </Col>

          <Col className="f-text" xs={12} md={6}>
            <ul className="list-inline">
              <li className="list-inline-item">
                Follow Us & Stay Informed
                <br />
                <a
                  href="https://www.linkedin.com/company/mesmr-io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="social-icon" src={linkedIn} />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="copyright-content py-3">
          <Col>
            <p className="ft-12">
              © 2024 MESMR Global Inc. All rights reserved
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Footer;
