import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function Popup({ showPopup, onHandleClose, videoSrc }) {
  return (
    <>
      <Modal
        show={showPopup}
        onHide={onHandleClose}
        size="lg"
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {
            <div className="vimeo-block">
              <iframe
                src={videoSrc}
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                className="vimeo-1"
                title="Mantia Extended"
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Popup;
