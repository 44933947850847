import React, { useState } from "react";
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  ContactForm,
} from "../components";
import { mute, unmute } from "assets";
import { useLocation } from "react-router-dom";
function Home() {
  const [muted, setMuted] = useState(true);
  const handleToggleMute = () => setMuted((current) => !current);
  let location = useLocation();

  React.useEffect(() => {
    if (location.hash) {
      let s = location?.hash?.slice(1);
      document.getElementById(s)?.scrollIntoView();
    }
  }, [location]);
  
  
    const isMobile = () => {
      return window.innerWidth <= 768;
    };


  return (
    <>
      <div id="home">
        
        <video
          className="video-block"
          src={isMobile() ? "https://mesmr.s3.amazonaws.com/Mesmr+Website+Showreel_v.mp4" : "https://mesmr.s3.amazonaws.com/Mesmr+Website+Showreel.mp4"}    
          autoPlay={true}
          loop={true}
          muted={muted}
        ></video>
        <button onClick={handleToggleMute} className="control">
          <img className="v-control" src={muted ? mute : unmute} />
        </button>
      </div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <ContactForm />
    </>
  );
}
export default Home;
