import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { img1, img2, img3, img4 } from "../assets";

function SectionThree() {
  return (
    <div id="services" className="section section-3 b-black-2">
      <Container>
        <Row className="text-center">
          <Col md={12}>
            <h2 className="section-heading ft-700 ft-64 ">
              We engage at different levels.
            </h2>
          </Col>
        </Row>
        <Row className="mb-4 bg-black tt-block ">
          <Col xs={12} md={4} className="ps-0">
            <img className="img-fluid" src={img1} />
          </Col>
          <Col xs={12} md={8}>
            <h3 className="panel-head ft-24 ft-700">Strategic Consulting</h3>
            <p className="lh-28 ft-20">
              Consulting with a brand to assess its readiness to move towards
              delivering experiences &ndash; the Story, the Values, the Content
              Platform it leverages, the brand&apos;s readiness to migrate
              customers from Journey Touchpoints to Journey &ldquo;Experience
              &ndash; points&rdquo;, the Key Performance Indicators in place
              &ndash; evaluating the entire ecosystem that impacts a brands
              ability to engage a community.
            </p>
          </Col>
        </Row>
        <Row className="mb-4 b-black tt-block">
          <Col xs={12} md={4} className="ps-0">
            <img className="img-fluid padding-left:0px" src={img2} />
          </Col>
          <Col xs={12} md={8}>
            <h3 className="panel-head ft-24 ft-700">
              End to End Experience Solutions Driven by Content
            </h3>
            <p className="lh-28 ft-20">
              From research & analysis, creative design, brand identity, videos,
              motion graphics, creative copy, 3D designs, Unreal and Unity
              development &ndash; we are focused on taking branded content to
              the next level.
            </p>
          </Col>
        </Row>
        <Row className="mb-4 b-black tt-block">
          <Col xs={12} md={4} className="ps-0">
            <img className="img-fluid" src={img3} />
          </Col>
          <Col xs={12} md={8}>
            <h3 className="panel-head ft-24 ft-700 ">
              Custom Technology Design and Development
            </h3>
            <p className="lh-28 ft-20">
              Designing, developing, and deploying custom applications for the
              Marketing Ecosystem that enables Brands to solve their unique
              needs, communicate with their consumers at a personalized level,
              instead of the &ldquo;off-the-shelf&rdquo; solutions that only
              half-solve the purpose and twice as expensive to customize.
            </p>
          </Col>
        </Row>
        <Row className="mb-4 b-black tt-block">
          <Col xs={12} md={4} className="ps-0">
            <img className="img-fluid" src={img4} />
          </Col>
          <Col xs={12} md={8}>
            <h3 className="panel-head ft-24 ft-700 ">Community Engagement</h3>
            <p className="lh-28 ft-20">
              From designing social media strategies, to content publishing, to
              community monitoring and ongoing engagement, including on next gen
              platforms such as Discord and Reddit, created to drive both
              quantitative and qualitative ROI for brands.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionThree;
