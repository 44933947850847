import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Carousel } from "react-bootstrap";
import { slideOne, slidetwo } from "utils/constant";
import Popup from "./Popup";

function SectionFive() {
  const [show, setShow] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const enablePopup = (e, videoUrl) => {
    setVideoSrc(videoUrl);
    setShow(true);
  };
  useEffect(() => {}, [show, videoSrc]);
  return (
    <div id="portfolio" className="section section-5 b-black-2">
      <Container>
        <Row className="text-center">
          <Col md={12}>
            <h2 className="section-heading ft-700 ft-64">
              What we enjoy doing.
            </h2>
          </Col>
        </Row>
        <Row>
          <Carousel data-bs-theme="white" interval={null}>
            <Carousel.Item>
              <Row>
                {slideOne &&
                  slideOne.map(({ index, title, image, para, videoUrl }) => (
                    <>
                      {" "}
                      <Col md={4} className="mb-3" key={index}>
                        <div className="d-card p-2">
                          <div className="img-container">
                            <img
                              className="img-fluid"
                              alt={title}
                              src={image}
                              onClick={(e) => enablePopup(e, videoUrl)}
                            />
                            <div className="play-button">
                              <svg
                                onClick={(e) => enablePopup(e, videoUrl)}
                                width="50px"
                                height="50px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="d-card-body">
                            <h3 className="panel-head ft-20 ft-700 ft-poppins">
                              {title}
                            </h3>
                            <p  className="ft-22">
                              {para}
                              </p>
                          </div>
                        </div>
                      </Col>
                    </>
                  ))}
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                {slidetwo &&
                  slidetwo.map(({ title, image, para, videoUrl }) => (
                    <>
                      {" "}
                      <Col md={4} className="mb-3">
                        <div className="d-card">
                          <div className="img-container">
                            <img
                              className="img-fluid"
                              alt="100%x280"
                              src={image}
                              onClick={(e) => enablePopup(e, videoUrl)}
                            />
                            <div className="play-button">
                              <svg
                                onClick={(e) => enablePopup(e, videoUrl)}
                                width="50px"
                                height="50px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="d-card-body">
                            <h3 className="panel-head ft-18 ft-700 ft-poppins">
                              {title}
                            </h3>
                            <p>{para}</p>
                          </div>
                        </div>
                      </Col>
                    </>
                  ))}
              </Row>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
      <Popup
        showPopup={show}
        onHandleClose={() => setShow(false)}
        videoSrc={videoSrc}
      />
    </div>
  );
}

export default SectionFive;
