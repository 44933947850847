import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Privacy() {
  return (
    <div className="ct-body-common">
      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <h1>Privacy Policy</h1>
              <br />
              Last updated - May 01, 2023
            </div>
            <p className="ft-tc ft-24">
              <br />
              <br />
              MESMR Global Inc. is committed to protecting your privacy. This
              privacy policy applies to data we collect when you use our
              websites and other online products, mobile applications, and
              services that link to this privacy policy or when you otherwise
              interact with us. There are various ways that you might interact
              with MESMR, and the data you provide when doing so allows us to
              improve our services. By using this website and by supplying your
              details to MESMR you consent to MESMR collecting and processing
              your data.
              <br />
              <br />
              Thank you for visiting our website. This privacy policy outlines
              how we collect, use, and protect your personal information when
              you use our website.
            </p>

            <div className="privacy-content">
              <p className="ft-tc ft-22">1.Information we collect </p>{" "}
              <p className="ft-22">
                We may collect personal information such as your name, email
                address, phone number, and other contact information when you
                voluntarily submit it through our website's contact forms or
                subscribe to our newsletter. We may also collect non-personal
                information such as your IP address and browser type through the
                use of cookies or other tracking technologies.{" "}
              </p>
              <p className="ft-tc ft-22">2.Use of information </p>{" "}
              <p className="ft-22">
                We use the personal information you provide to us to respond to
                your inquiries, provide you with information about our products
                or services, and to send you newsletters or other marketing
                communications. We may also use your non-personal information to
                analyze website usage trends and to improve our website's
                functionality.{" "}
              </p>
              <p className="ft-tc ft-22">3.Disclosure of information </p>{" "}
              <p className="ft-22">
                We will not disclose your personal information to third parties,
                except as necessary to fulfill your request, or if required by
                law.{" "}
              </p>
              <p className="ft-tc ft-22">4.Security </p>{" "}
              <p className="ft-22">
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, we cannot
                guarantee the security of your information, and we are not
                responsible for any breach of security beyond our control.{" "}
              </p>
              <p className="ft-tc ft-22">5.Third-party links </p>{" "}
              <p className="ft-22">
                Our website may contain links to third-party websites. These
                websites have their own privacy policies, and we are not
                responsible for their practices. We encourage you to review
                their policies before providing them with any personal
                information.{" "}
              </p>
              <p className="ft-tc ft-22">6.Children's privacy </p>{" "}
              <p className="ft-22">
                Our website is not intended for use by children under the age of
                13. We do not knowingly collect personal information from
                children under the age of 13.{" "}
              </p>
              <p className="ft-tc ft-22">7.Changes to this policy </p>{" "}
              <p className="ft-22">
                We may update this privacy policy from time to time. We will
                post the updated policy on our website and indicate the date of
                the latest revision. If you have any questions or concerns about
                this privacy policy, please contact us through our website's
                contact form.{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Privacy;
