import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import animeImg from "../assets/text-ani.gif";
import React from "react";
function SectionOne() {
  return (
    <div className="section section-1 b-black-2" id="about">
      <Container>
        <Row>
          <Col md={6}>
            <h2 className="section-heading ft-700 ft-64 py-4">
              What moves us.
            </h2>
            <Row>
              <Col md={9}>
                <p className="ft-22 lh-28">
                  We help brands reach consumers and drive growth through our
                  creative and technology solutions. We are a new kind of hybrid
                  – bringing creative design together with emerging tech.{" "}
                  <span className="text-uppercase">We are Mesmr</span>.
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <img
              className="anim-text img-fluid"
              src={animeImg}
              alt-text="anime text"
            />
          </Col>
        </Row>
        <Row className="py-5 align-items-center">
          {/* <Col md={4}>
            <p className="ft-12 text-md-end m-0">
              Join the bunch of leading brands that trust Mesmr to transform
              their consumer interactions. Together, we can create memorable
              experiences that highlight your brand's values and drive sustained
              growth.
            </p>
          </Col> */}
          <Col md={6}>
            <Row>
              <Col md={12} className=" d-flex align-items-center">
                <span className="ft-64 ft-900 pe-2">100+</span>
                <span className="ft-22">
                  {" "}
                  Projects delivered<br />
                  on time
                </span>
              </Col>
              <Col className="mb-2">
                <a className=" btn btn-dark btn-service" href="#services">
                  SEE SERVICES
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <p className="para-top ft-22">
              {" "}
              We foster a collaborative approach, working closely
              with our clients to seamlessly integrate their vision with our
              expertise, resulting in impactful and sustainable growth
              strategies.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionOne;
