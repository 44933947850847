import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Cookie() {
  return (
    <div className="ct-body-common">
      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <h1>Cookie Policy</h1>
            </div>
            <p className="ft-tc ft-24">
              {" "}
              Thank you for visiting our website. This cookie policy explains
              how we use cookies and similar technologies on our website. By
              using our website, you consent to our use of cookies in accordance
              with this policy.
            </p>

            <div className="cookie-content">
              <p className="ft-tc ft-22">1.What are cookies?</p>
              <p className="ft-22">
                Cookies are small text files that are placed on your device when
                you visit a website. They are widely used to make websites work
                or to improve their efficiency, as well as to provide
                information to the website owner. Cookies cannot harm your
                device or access any personal information stored on it.
              </p>

              <p className="ft-tc ft-22">2.Types of cookies</p>
              <p className="ft-22">
                We use both session cookies and persistent cookies (need to
                verify this) on our website. Session cookies are temporary
                cookies that are erased when you close your browser, while
                persistent cookies remain on your device until they expire or
                you delete them.
              </p>

              <p className="ft-tc ft-22">3.Purpose of cookies</p>
              <p className="ft-22">
                We use cookies to analyze website usage, personalize your
                experience, and to deliver targeted advertising. Specifically,
                we use cookies to: Remember your preferences and settings
                Measure and analyze website usage Personalize your experience
                and content Deliver targeted advertising
              </p>

              <p className="ft-tc ft-22">4.Third-party cookies</p>
              <p className="ft-22">
                We also use third-party cookies on our website, such as Google
                Analytics and advertising partners, to help us analyze website
                usage and to deliver targeted advertising. These third-party
                cookies may also collect information about your browsing habits
                across multiple websites.
              </p>

              <p className="ft-tc ft-22">5.Cookie management</p>
              <p className="ft-22">
                You can control and manage cookies through your browser
                settings. However, please note that disabling cookies may limit
                your ability to use certain features of our website.
              </p>

              <p className="ft-tc ft-22">6.Changes to this policy</p>
              <p className="ft-22">
                We may update this cookie policy from time to time. We will post
                the updated policy on our website and indicate the date of the
                latest revision.
                <br />
                <br />
                If you have any questions or concerns about this cookie policy,
                please contact us through our website's contact form.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Cookie;
