import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function SectionTwo() {
  return (
    <div className="section section-2" id="capabilities">
      <Container>
        {/* <Row>
          <Col md={12}>
            <p className="quoted-text ft-700 ft-36 text-center">
              “Nurturing and Engaging a Community around the Experience
              <br />
              and more importantly the Brand itself.”
            </p>
          </Col>
        </Row> */}
        <Row className="text-center">
          <Col md={12}>
            <h2 className="section-heading ft-700 ft-64">We are good at.</h2>
          </Col>
        </Row>
        <Row className="d-flex aligh-items-stretch">
          <Col md={4} className="d-flex">
            <div className="p-4 b-black-1-1">
              <h3 className="panel-head ft-24 ft-700">Experience Design</h3>
              <p className="lh-28 ft-20">
                Brands first created content to communicate, then they created
                content to tell a story. Brands will now create content to drive
                Experiences.
              </p>
              <p className="lh-28 ft-20">
                By bringing together the latest in Interactive and Immersive
                Technologies such as 3D, Unreal Engine, Unity and other Gaming
                tech – we enable brands to drive experiences about their values,
                market positioning and more.
              </p>
            </div>
          </Col>
          <Col md={4} className="d-flex">
            <div className="p-4 b-black-1-1">
              <h3 className="panel-head  ft-24 ft-700">
                Personalization at Scale
              </h3>
              <p className="lh-28 ft-20">
                It is not enough if technology just enables reach and scale, it
                needs to engage and connect at a personal level.
              </p>
              <p  className="ft-20">
                {" "}
                That is the sweet spot where technology meets data - to engage
                with an entire community, yet at a personal level.  We source,
                build and integrate technologies that drive personalization of
                brand experiences.
              </p>
            </div>
          </Col>
          <Col md={4} className="d-flex">
            <div className="p-4 b-black-1-1">
              <h3 className="panel-head  ft-24 ft-700">Community Engagement</h3>
              <p className="ft-20">
                We believe that an engaged community is a healthy community.
                True community engagement is beyond what metrices can capture.
              </p>
              <p className="ft-20">
                It is the purpose, the interactions, the objectives, the shared
                passion that the community assigns to the brand that requires
                constant nurturing.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionTwo;
