import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { c1, c2, c3, c4,V1, V2, V3, V4 } from "assets";

function SectionFour() {
  return (
    <div id="clients" className="section section-4">
      <Container>
        <Row className="text-center">
          <Col md={12}>
            <h2 className="section-heading ft-700 ft-64 we-support">
              We support the best.
            </h2>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={c1} />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={c2} />
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={c3} />
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={c4} />
            </div>
          </Col>
        </Row>

        <Row className="text-center" id="partners">
          <Col md={12}>
            <h2 className="section-heading ft-700 ft-64 by-work">
              By working with the best.
            </h2>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={V1} />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={V2} />
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={V3} />
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className="image-container">
              <img className="img-fluid customer-logo" src={V4} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionFour;
