import { Header, Footer } from "components";
import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

function Layout() {
  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  return (
    <div className={isMobile() ? "site-content mobile" : "site-content"}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
export default Layout;
