import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Terms() {
  return (
    <div className="ct-body-common">
      <Container>
        <Row>
          <Col>
            <div className="text-center">
              <h1>Terms And Conditions</h1>
            </div>
            <p className="ft-tc ft-22">
              {" "}
              Thank you for visiting our website. By accessing and using this
              website, you agree to comply with and be bound by the following
              terms and conditions of use:
            </p>
            <div className="terms-content">
              <p className="ft-tc ft-22"> 1.Use of website </p>{" "}
              <p className="ft-22">
                The content of this website is for your general information and
                use only. It is subject to change without notice. You may not
                modify, distribute, transmit, display, perform, reproduce,
                publish, license, create derivative works from, transfer, or
                sell any information, software, products or services obtained
                from this website.{" "}
              </p>
              <p className="ft-tc ft-22"> 2.Intellectual property rights </p>{" "}
              <p className="ft-22">
                All intellectual property rights in the content, design, layout,
                and graphics of this website are owned by MESMR Global Inc., or
                our licensors. You may not reproduce, modify, distribute, or
                display any of our intellectual property without our prior
                written consent.{" "}
              </p>
              <p className="ft-tc ft-22">
                3.Disclaimer of warranties and liability
              </p>
              <p className="ft-22">
                We make no representations or warranties of any kind, express or
                implied, about the completeness, accuracy, reliability,
                suitability, or availability of the website or the information,
                products, services, or related graphics contained on the website
                for any purpose. We shall not be liable for any direct,
                indirect, incidental, special, or consequential damages arising
                out of the use or inability to use the website or the
                information, products, services, or related graphics contained
                on the website.{" "}
              </p>
              <p className="ft-tc ft-22"> 4.Third-party links </p>{" "}
              <p className="ft-22">
                This website may contain links to third-party websites. These
                links are provided for your convenience to provide further
                information. We have no control over the content of these
                websites and accept no responsibility for them or for any loss
                or damage that may arise from your use of them.{" "}
              </p>
              <p className="ft-tc ft-22"> 5.Privacy policy </p>{" "}
              <p className="ft-22">
                Your use of this website is subject to our privacy policy, which
                outlines how we collect, use, and protect your personal
                information.{" "}
              </p>
              <p className="ft-tc ft-22"> 6.Indemnification </p>{" "}
              <p className="ft-22">
                You agree to indemnify, defend, and hold harmless MESMR Global
                Inc., our affiliates, officers, directors, employees, agents,
                and licensors from and against any and all claims, liabilities,
                damages, losses, costs, expenses, or fees (including reasonable
                attorneys' fees) arising from your use of this website or your
                breach of these terms and conditions.{" "}
              </p>
              <p className="ft-tc ft-22"> 7.Governing law and jurisdiction </p>{" "}
              <p className="ft-22">
                These terms and conditions shall be governed by and construed in
                accordance with the laws of the jurisdiction in which we
                operate. Any dispute arising from or in connection with these
                terms and conditions shall be subject to the exclusive
                jurisdiction of the courts in that jurisdiction.{" "}
              </p>
              <p className="ft-tc ft-22"> 8.Changes to terms and conditions </p>{" "}
              <p className="ft-22">
                We reserve the right to modify these terms and conditions at any
                time. You should check this page periodically to ensure that you
                are aware of any changes. Your continued use of the website
                following any changes to these terms and conditions constitutes
                your acceptance of those changes.
                <br />
                <br />
                If you have any questions or concerns about these terms and
                conditions, please contact us through our website's contact
                form.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Terms;
